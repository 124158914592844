<template>
  <b-overlay no-fade>
      <b-card style="font-size: 1.4rem;"  class="mt-2 bg-info p-2 ">
        <b-card-header>
          <h3>
            Onward and Upward
          </h3>
          <div class="logo-container">
            <img :src="require('@/assets/images/seaslogo.png')" class="file-image" />
        <span style="font-size: small;">Scientific Instrument Machine Shop</span>
          </div>
         

        </b-card-header>
        <b-card-text>

        <p style="padding-left: 3%; padding-right: 3%;">
          According to <a style="text-decoration: underline;" href="https://www.thefreedictionary.com/">thefreedictionary.com</a>, “onward
        and upward” is an expression that is defined as “something that you say to encourage someone to forget an unpleasant experience or failure and to think about the future instead.” In my view, the adoption of an “onward and upward” position requires a few key ingredients: optimism, empathy, and curiosity.
        Optimism is defined as the ability to overcome adversity. Optimists are those folks who possess the presence of mind to evaluate situations that are intense, emotional, or disappointing as something you are passing through… rather than something chronic, constant, or never-ending.
        <br>
        <br>
        

        Adoption of “onward and upward” spurs a dialogue more likely to move us forward. Those skilled in this practice often make statements to themselves such as: “What’s done is done,” or “Lick your wounds and move on.” 
        In other words, they move “onward and upward”! These folks do not just relive negative or unpleasant experiences, which would be more like “backward and downward,” so to speak.
         “Onward and upward” sets a positive direction—one that relies on an “optimistic explanatory style” <a style="text-decoration: underline;" href="https://www.simonandschuster.com/books/Learned-Optimism/Martin-E-P-Seligman/9780743519229">(Learned Optimism, by Martin Seligman)</a>   that is necessary but, in and of itself, not sufficient. 
         To adopt optimism for the long term, one must possess and display empathy.
        <br>
        <br>
        
        Empathy is essentially the ability to understand situations from another’s perspective—without judgment. The lack of judgment is a result of monitoring one’s internal dialogue and moving away from thoughts such as,
         “What an idiot” to “What feeling is driving the behavior being displayed?” “How would I feel if it were me?” and “What can I do to help?”
        <br>
        <br>
        Empathy becomes the measure of our own ability to choose to respond rather than to react. When we focus on how other people interpret a situation, it sheds light on the best approach to help with the adoption of “onward and upward.”
         Trust me… If you just walk into a group or team of folks experiencing concern, disappointment or anger over a situation, simply telling them, “Hey, onward and upward!” is most likely to yield a negative response even if you claim it as positive. 
         This is because interpretation occurs at the recipient’s end and not at the sender's.
        <br>
        <br>

        <b>
          This is where curiosity comes in. To be effective one must be curious… curious about how people are feeling, curious about why things happen, curious about the future, curious about steps to take to navigate difficult waters, etc. 
        Curiosity affords one the opportunity to speak non-offensively and fosters the art of asking questions effectively and skillfully. Curiosity is an intellectual pursuit that suspends judgment and inspires honest communication.
        </b>
       
        <br>
        <br>

        The reality is that “onward and upward” is the call of the 21st century. There is simply not time enough to waste on “backward and downward.” 
        <br>
        <br>
        <b>
          So, what is YOUR plan?

        </b>
        </p>
       
        </b-card-text>
     
        <b-card-footer class="text-right">
              <b-button @click="downloadMantra" variant="primary">

                Download as PDF
              </b-button>





        </b-card-footer>

      </b-card>

       
       
      
     

  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import { BSpinner, BOverlay, BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import store from '@/store';
import homeStoreModule from './homeStoreModule.js';
import { onUnmounted } from '@vue/composition-api';

export default {
  components: {
    BSpinner,
    BOverlay,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ToastificationContent

  },
  setup() {
    const HOME_APP_STORE_MODULE_NAME = 'home';
    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME)) store.registerModule(HOME_APP_STORE_MODULE_NAME, homeStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HOME_APP_STORE_MODULE_NAME)) store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
    });
  },


  
  methods:{
    downloadMantra(){


      
      store
          .dispatch('home/downloadMantra')
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
              const link = document.createElement('a');
              link.href = url;

              link.setAttribute('download', 'Onward and Upward.pdf');
              document.body.appendChild(link);
              link.click();

          })
          .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'An error occurred',
                  text: 'Please try again later or contact support.',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              });
            });

     
    },

  
  }

};


</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style scoped>


.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  text-align: center; /* Center text horizontally */
}

.file-image {
  width: 100%;
  height: 100px;
  max-width: 100%; /* Ensure the image doesn't exceed its container */

}

</style>
